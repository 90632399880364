import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Card,
  Dialog,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import Routes from "../../../../router/Routes";
import {Link} from "react-router-dom";
import SampleImage from "assets/images/pages/patient/sample.png";
import CloseIcon from "assets/images/icons/close.svg";
import TrackIcon from "assets/images/icons/track.svg";


const useStyles = makeStyles((theme) => ({
  card: {
    border: "1px solid #DDE3EE",
    borderRadius: 8,
    padding: "20px 16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    boxShadow: "none",
    marginBottom: 24,
    background: "#F7F8FB",
    "& > .MuiBox-root": {
      marginBottom: 20,
      "& img": {
        width: 29,
        marginRight: 30,
      },
      display: "flex",
      alignItems: "center",
      "& .MuiTypography-root": {
        fontFamily: "Lato",
        fontWeight: 700,
      },
    },
    [theme.breakpoints.up("lg")]: {
      background: "#FFF",
      padding: theme.spacing(3, 4),
      flexDirection: "row",
      alignItems: "center",
      "& > .MuiBox-root": {
        marginBottom: 0,
        "& img": {
          width: "45px!important",
          marginRight: "38px!important",
        },
      },
    },
  },
  name: {
    fontSize: 20,
    color: "#003366",
    marginBottom: 8,
    [theme.breakpoints.up("lg")]: {
      fontSize: 24,
    },
  },
  date: {
    fontWeight: "400!important",
    fontSize: 14,
    color: "#454D5F",
    marginBottom: 0,
    [theme.breakpoints.up("lg")]: {
      fontSize: 16,
      fontWeight: "700!important",
      marginBottom: 8,
    },
  },
  description: {
    display: "none",
    fontSize: 16,
    color: "#003366",
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
  shipmentDescription: {
    fontSize: 14,
    color: "#003366",
    cursor: "pointer",
    [theme.breakpoints.up("lg")]: {
      fontSize: 16,
      fontWeight: "700!important",
      marginBottom: 8,
    },
  },
  detailsLink : {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    textDecoration: "none"
  },
  detailsButton: {
    width: "100%",
    height: 56,
    border: "2px solid #003366",
    borderRadius: 8,
    textTransform: "none",
    float: "right",
    "& .MuiTypography-root": {
      fontFamily: "Lato",
      fontWeight: 700,
      fontSize: 16,
      color: "#003366",
      /*marginLeft: 16*/
    },
    [theme.breakpoints.up("lg")]: {
      width: 217,
    },
  },
  trackButton: {
    width: "100%",
    height: 56,
    border: "2px solid #003366",
    borderRadius: 8,
    textTransform: "none",
    float: "right",
    marginLeft: 5,
    "& .MuiTypography-root": {
      fontFamily: "Lato",
      fontWeight: 700,
      fontSize: 16,
      color: "#003366",
      /*marginLeft: 16*/
    },
    [theme.breakpoints.up("lg")]: {
      width: 217,
    },
  },
  dialog: {
    "& .MuiPaper-root": {
      width: 1118,
      maxWidth: 1118,
    },
    "& .MuiGrid-item": {
      marginBottom: theme.spacing(1),
    },
  },
  dialogHeader: {
    padding: "20px 32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiTypography-root": {
      fontFamily: "Lato",
      fontWeight: 700,
      fontSize: 20,
      color: "#003366",
    },
  },
  closeButton: {
    width: 32,
    height: 32,
    minWidth: 32,
    border: "1px solid #DDE3EE",
    borderRadius: 8,
  },
  dialogBody: {
    padding: theme.spacing(0, 4, 10),
  },
  table: {
    "& td": {
      fontSize: 16,
      [theme.breakpoints.down("md")]: {
        fontSize: 10
      }
    },
  },
}));

const ProbeTicket = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [shipmentDetails, setShipmentDetails] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const openShipmentDetails = (shipment) => {
    if (shipment) {
      setShipmentDetails(shipment);
      if (shipment.status_history) {
        setOpen(true);
      }
    }

  }

  return (
    <>
      <Card className={classes.card}>
        <Box>
          <img src={SampleImage} alt=":( Not Found" />
          <Box>
            <Typography className={classes.name}>
              Próbka nr {props.code}
            </Typography>
            <Typography className={classes.date}>
              {t("pages.patient.probes.statuses."+props.status)}
            </Typography>
            {props.set?.shipment?.last_status &&
                <Typography className={classes.shipmentDescription} onClick={() => openShipmentDetails(props.set?.shipment)}>Status przesyłki: {props.set.shipment?.last_status}</Typography>}
          </Box>
        </Box>
        <Link to={Routes.Dashboard.Patient.ProbeDetail(props.id)} className={classes.detailsLink}>
          <Button className={classes.detailsButton} >
            {/*<img src={TrackIcon} alt=":( Not Found" />*/}
            <Typography>{t("pages.patient.probesList.details")}</Typography>
          </Button>
        </Link>


      </Card>
      <Dialog onClose={handleClose} className={classes.dialog} open={open}>
        <Box className={classes.dialogHeader}>
          <Typography>Śledź przesyłkę</Typography>
          <Button onClick={handleClose} className={classes.closeButton}>
            <img src={CloseIcon} alt=":( Not Found" />
          </Button>
        </Box>
        <Box className={classes.dialogBody}>
          <Table className={classes.table}>
            <TableBody>
              {shipmentDetails && shipmentDetails.status_history?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.nazwa}</TableCell>
                  <TableCell>{row.czas}</TableCell>
                  <TableCell>{row.jednostka?.nazwa}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Dialog>
    </>
  );
};

export default ProbeTicket;
