import React, {useState} from "react";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    Grid,
    makeStyles,
    MenuItem,
    Select,
    Typography,
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import CloseIcon from "assets/images/icons/close.svg";
import TextInput from "components/form/TextInput";
import {useDispatch, useSelector} from "react-redux";
import API from "apis/API";
import * as Actions from "store/actions/PatientAction";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiPaper-root": {
            width: 477,
            padding: "20px 32px 36px",
        },
        [theme.breakpoints.down("md")]: {
            "& .MuiDialog-container": {
                justifyContent: "center",
                alignItems: "center",
                "& .MuiPaper-root": {
                    minHeight: "100vh",
                    zIndex: 9999,
                    position: "relative",
                    margin: 0,
                    borderRadius: 0,
                    padding: "16px 24px",
                },
            },
        },
    },
    dialogHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .MuiTypography-root": {
            fontWeight: 700,
            fontSize: 20,
            color: "#003366",
        },
        [theme.breakpoints.down("md")]: {
            "& .MuiTypography-root": {
                fontSize: 16,
            },
        },
    },
    closeButton: {
        minWidth: 32,
        width: 32,
        height: 32,
        borderRadius: 8,
        background: "#FFFFFF",
        border: "1px solid #DDE3EE",
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    dialogBody: {
        padding: theme.spacing(4, 0),
    },
    formGroupTitle: {
        fontWeight: 700,
        fontSize: 16,
        color: "#454D5F",
        marginBottom: 18,
    },
    selectBox: {
        marginBottom: 32,
        "& .MuiTypography-root": {
            fontSize: 14,
            color: "#6C7689",
            marginBottom: 2,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #A6AEBF",
            borderRadius: 8,
        },
    },
    dialogFooter: {
        display: "flex",
        justifyContent: "flex-end",
        "& .MuiButton-root": {
            width: 140,
            height: 56,
            borderRadius: 8,
            fontFamily: "Lato",
            fontSize: 16,
            fontWeight: 700,
            textTransform: "none",
            marginLeft: theme.spacing(2),
            border: "1px solid #003366",
            "&:first-child": {
                color: "#003366",
            },
            "&:last-child": {
                color: "#FFF",
                background: "#003366",
            },
        },
        [theme.breakpoints.down("md")]: {
            position: "absolute",
            bottom: 34,
            left: 24,
            right: 24,
            justifyContent: "space-between",
            "& .MuiButton-root": {
                marginLeft: 0,
                width: "48%",
            },
        },
    },
}));

const OrderSetModal = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [isSaving, setIsSaving] = useState(false);
    const {data} = useSelector((state) => state.patient);
    const [formData, setFormData] = useState({
        amount: 1,
        street: data?.address_street,
        building: data?.address_building,
        postcode: data?.address_postcode,
        city: data?.address_city,
    });

    const handleChange = (e) => {
        if (e.target.name == 'postcode') {
            if (e.target.value.length > 2 && !e.target.value.includes('-')) {
                e.target.value = e.target.value.slice(0, 2) + "-" + e.target.value.slice(2);
            }
        }
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSave = () => {
        setIsSaving(true);
        API.patient.probe.addSet(formData).then((res) => {
            setIsSaving(false);
            props.onClose();
            dispatch(Actions.addOrderSetData(res.data.probe_set));
        });
    };

    return (
        <Dialog {...props} className={classes.root}>
            <Box className={classes.dialogHeader}>
                <Typography>Zamawianie zestawów</Typography>
                <Button className={classes.closeButton} onClick={props.onClose}>
                    <img src={CloseIcon} alt=":( Not Found"/>
                </Button>
            </Box>
            <Box className={classes.dialogBody}>
                <Box className={classes.selectBox}>
                    <Typography>Ilość</Typography>
                    <Select
                        value={formData.amount}
                        onChange={handleChange}
                        name="amount"
                        variant="outlined"
                        fullWidth
                    >
                        {Array.from(Array(10)).map((value, key) => {
                            return <MenuItem key={key} value={key + 1}>{key + 1}</MenuItem>;
                        })}
                    </Select>
                </Box>
                <Typography className={classes.formGroupTitle}>Adres</Typography>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextInput
                            label="Ulica*"
                            name="street"
                            value={formData.street}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Budynek/lokal*"
                            name="building"
                            value={formData.building}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            label="Kod pocztowy*"
                            name="postcode"
                            inputProps={{ maxLength: 6 }}
                            value={formData.postcode}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            label="Miasto*"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box className={classes.dialogFooter}>
                <Button onClick={props.onClose}>{t("common.cancel")}</Button>
                <Button onClick={handleSave}>
                    {isSaving ? (
                        <CircularProgress size={30} style={{color: "#FFF"}}/>
                    ) : (
                        <>{t("common.add")}</>
                    )}
                </Button>
            </Box>
        </Dialog>
    );
};

export default OrderSetModal;
