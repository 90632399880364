const Constants = {
  Locales: ["pl", "en"],
  DefaultStringLimit: 255,
  EmailPattern: new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  ),
  LatitudePattern: new RegExp(/^((-)?(0|([1-9][0-9]*))(\.[0-9]+)?)$/),
  LongitudePattern: new RegExp(/^((-)?(0|([1-9][0-9]*))(\.[0-9]+)?)$/),
  FullHexCodePattern: new RegExp(/^#[0-9A-F]{6}$/i),
  days: {
    Monday: "Poniedziałek",
    Tuesday: "Wtorek",
    Wednesday: "Środa",
    Thursday: "Czwartek",
    Friday: "Piątek",
    Saturday: "Sobota",
    Sunday: "Niedziela",
  },
  order: [
    "Pierwsza wizyta",
    "Druga wizyta",
    "Trzecia wizyta",
    "Czwarta wizyta",
    "Piąta wizyta",
    "Szósta wizyta",
    "Siódma wizyta",
    "Ósma wizyta",
    "Dziewiąta wizyta",
    "Dziesiąta wizyta",
  ],
};

export default Constants;
