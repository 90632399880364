import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import Routes from "./Routes";
import Admin from "layouts/Admin";

import Login from "pages/Auth/Login";
import Attachments from "components/tabs/Attachments";
import DoctorComments from "components/tabs/Doctor/DoctorComments";
import Medications from "components/tabs/Medications";
import Messages from "components/tabs/Messages";
import Personal from "components/tabs/Personal";
import Probes from "components/tabs/Probes/Probes";
import ProbeDetails from "components/tabs/Probes/ProbeDetails";
import Visits from "components/tabs/Visits/Visits";
import Documents from "pages/Documents/Documents";
import ChangePassword from "pages/ChangePassword";
import VisitDetail from "components/tabs/Visits/VisitDetail";

const AdminLayoutRoutes = [
    {
        component: Attachments,
        path: Routes.Dashboard.Patient.Attachments,
    },
    {
        component: DoctorComments,
        path: Routes.Dashboard.Patient.DoctorComments,
    },
    {
        component: Medications,
        path: Routes.Dashboard.Patient.Medications,
    },
    {
        component: Messages,
        path: Routes.Dashboard.Patient.Messages,
    },
    {
        component: Personal,
        path: Routes.Dashboard.Patient.Personal,
    },
    {
        component: Probes,
        path: Routes.Dashboard.Patient.Probes,
    },
    {
        component: ProbeDetails,
        path: Routes.Dashboard.Patient.ProbeDetail()
    },
    {
        component: Visits,
        path: Routes.Dashboard.Patient.Visits,
    },
    {
        component: Documents,
        title: "Dane osobowe i medyczne",
        path: Routes.Dashboard.Documents,
    },
    {
        component: ChangePassword,
        title: "Dane osobowe i medyczne",
        path: Routes.Dashboard.ChangePassword,
    },
    {
        component: VisitDetail,
        title: "Zobacz kartę wizyty",
        path: Routes.Dashboard.Patient.VisitDetail(),
    },
];

const AdminLayout = () => {
    return (
        <Switch>
            <Admin>
                {AdminLayoutRoutes.map((route, index) => {
                    return <Route key={index} exact {...route} />;
                })}
            </Admin>
        </Switch>
    );
};

const WithoutLayoutRoutes = [
    {
        component: Login,
        title: "Login",
        path: Routes.Auth.Login,
    },
];

const WithoutLayout = () => {
    return (
        <Switch>
            {WithoutLayoutRoutes.map((route, index) => {
                return <Route key={index} exact {...route} />;
            })}
        </Switch>
    );
};

const RoutesRouter = () => {
    return (
        <Switch>
            <Redirect exact from="/" to={Routes.Auth.Login}/>
            <Redirect exact from="/patient" to={{pathname: Routes.Dashboard.Patient.Personal, state: {load: true}}}/>
            <Route
                path={AdminLayoutRoutes.map((route) => route.path)}
                exact
                component={AdminLayout}
            />
            <Route
                path={WithoutLayoutRoutes.map((route) => route.path)}
                exact
                component={WithoutLayout}
            />
            <Redirect to={Routes.Auth.Login}/>
        </Switch>
    );
};

export default RoutesRouter;
