import React, { useState } from "react";
import PatientCard from "pages/Patient/PatientCard";
import { useDispatch, useSelector } from "react-redux";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MobileHeader from "components/navigation/MobileHeader";
import Note from "./DoctorComment";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 16,
    color: "#003366",
    margin: theme.spacing(3, 0, 2),
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(3, 0),
      fontSize: 20,
      color: "#454D5F",
    },
  },
}));

const DoctorComments = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { notes } = useSelector((state) => state.patient?.data || []);

  return (
    <>
      <MobileHeader title="Komentarze lekarza" />
      <PatientCard active="comments">
        <Box>
          <Typography className={classes.title}>
            {t("pages.patient.doctorComments.title")}
          </Typography>
          {notes?.map((comment, index) => {
            return <Note {...comment} key={index} />;
          })}
        </Box>
      </PatientCard>
    </>
  );
};

export default DoctorComments;
