const Messages = {
  BeforeStartDate:
    "To pole musi mieć wartość przed lub równą czasie rozpoczęcia",
  SomeFieldsAreInvalid: "Niektóre pola są nieprawidłowe",
  MustBeFullHexCode: "To pole musi być prawidłowym kodem HEX (#AAAAAA)",
  FieldMustBeFilled: "To pole musi być wypełnione",
  FieldMustBeEqual: "Pole musi być równe",
  FieldTooLong: "Wartość tego pola jest za długa",
  InvalidEmail: "To pole musi być poprawnym adresem e-mail",
  InvalidLatitude:
    "To pole musi być prawidłową współrzędną (liczby z opcjonalnym wiodącym minusem i opcjonalną kropką od -90 do 90)",
  InvalidLongitude:
    "To pole musi być prawidłową współrzędną (liczby z opcjonalnym wiodącym minusem i opcjonalną kropką od -180 do 180)",
  InvalidDate: "To pole musi mieć poprawną datę",
  InvalidTime: "To pole musi mieć poprawny czas",
  InvalidDateTime: "To pole musi mieć poprawną datę i godzinę",
  BeforeEndDate: "To pole musi mieć wartość przed lub równą czasie zakończenia",
  AfterStartDate: "To pole musi mieć wartość po lub równym czasie rozpoczęcia",
};

export default Messages;
