import Constants from "./Constants";

const formatAddress = (source, elements =  ['address_street', 'address_building', 'address_apartment', 'address_postcode', 'address_city']) => {

    let addressFinalElements = [];
    elements.forEach(element => {
        if (source?.[element]) {
            addressFinalElements.push(source?.[element]);
        }
    });

    return addressFinalElements.join(', ');
};

export default formatAddress;
