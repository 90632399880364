const Routes = {
  Auth: {
    Login: "/login",
  },
  Dashboard: {
    Patient: {
      Personal: "/patient/personal",
      Attachments: "/patient/attachments",
      DoctorComments: "/patient/comments",
      Medications: "/patient/medications",
      Messages: "/patient/messages",
      Probes: "/patient/probes",
      ProbeDetail: (id = ':id') => `/patient/probes/${id}`,
      Visits: "/patient/visits",
      VisitDetail: (id = ':id') => `/patient/visits/${id}`,
    },
    Documents: "/documents",
    ChangePassword: "/changepassword",
  },
};

export default Routes;
