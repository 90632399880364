import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  button: {
    minWidth: theme.spacing(4),
    width: theme.spacing(4),
    height: theme.spacing(4),
    border: "1px solid #DDE3EE",
    borderRadius: theme.spacing(1),
    "& img": {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  },
}));

const IconButton = props => {
  const { onClick, icon, className, children } = props;
  const classes = useStyles();

  return (
    <Button onClick={onClick} className={clsx(classes.button, className)}>
      {children && children}
      {!children && <img src={icon} alt=":( Not Found" />}
    </Button>
  );
};

export default IconButton;
