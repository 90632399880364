import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import moment from "moment";
import FileImage from "assets/images/pages/patient/file.png";
import API from "apis/API";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: 42,
    "& > img": {
      marginRight: 24,
    },
  },
  name: {
    cursor: 'pointer',
    fontFamily: "Lato",
    textDecoration: "underline",
    fontWeight: 700,
    color: "#003366",
    fontSize: 14,
    marginBottom: 3,
  },
  date: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 14,
    color: "#6C7689",
    marginBottom: 8
  },
  label: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 10,
    color: "#003366",
    marginBottom: 5,
  },
  comment: {
    fontFamily: "Lato",
    fontSize: 14,
    color: "#454D5F",

    "& *": {
      margin: 0
    }
  },
}));

const Document = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleDownloadFile = (fileID, filename) => {
    const blobInterceptor = API.interceptors.request.use(
        (config) => {
          config.responseType = 'blob';
          return config;
        },
        function (error) {
          return Promise.reject(error);
        }
    );
    API.patient.file.download(fileID).then((res) => {
      API.interceptors.request.eject(blobInterceptor);
      var fileURL = window.URL.createObjectURL(new Blob([res.data]));
      var fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', filename);
      document.body.appendChild(fileLink);
      fileLink.click();
    });
  };


  return (
      <Box className={classes.root}>
        <img src={FileImage} alt="File" />
        <Box>
          <Typography className={classes.name} onClick={() =>
              handleDownloadFile(props.id, props.original_name)
          }>{props.original_name}</Typography>
          <Typography className={classes.date}>
            {t('pages.document.added')} {moment(props.updated_at).format('DD.MM.YYYY, HH:mm')}
          </Typography>
          <Box>
            <Typography className={classes.label}>
              {t('pages.patient.attachments.comment')}:
            </Typography>
            <Box
                className={classes.comment}
                dangerouslySetInnerHTML={{ __html: props.comment }}
            />
          </Box>
        </Box>
      </Box>
  );
};

export default Document;
