import React from "react";
import { makeStyles, TextField, Typography, Box } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(1)
  },
  input: {
    "& .MuiInputBase-root": {
      borderRadius: theme.spacing(1),
    },
    "& .MuiFormHelperText-root": {
      marginInline: 0
    }
  },
  requiredMark: {
    color: "#ff0000"
  }
}));

const FormInput = props => {
  const { title, name, value, onChange, placeholder, required = true, fullWidth = true, error, ...rest } = props;
  const classes = useStyles();

  return (
    <Box mb={2}>
      <Typography className={classes.title}>
        {title} {required && <span className={classes.requiredMark}>*</span>}
      </Typography>
      <TextField
        variant="outlined"
        className={classes.input}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        fullWidth={fullWidth}
        required={required}
        error={!!error}
        helperText={error}
        {...rest}
      />
    </Box>
  );
};

export default FormInput;
