import { useEffect } from "react";
import Routes from "router/Routes";
import Storage from 'utils/Storage';

const AppContainer = (props) => {
  useEffect(() => {
    const token = Storage.get('token');
    if(!token) {
      if(window.location.pathname !== Routes.Auth.Login) {
        window.location.href = Routes.Auth.Login;
      }
    }
  }, []);
  return props.children;
};

export default AppContainer;
