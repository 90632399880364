import React from "react";
import { Typography, Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  button: {
    height: theme.spacing(7),
    background: "#003366",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0, 3),
    margin: theme.spacing(0, 1),
    whiteSpace: "nowrap",
    "&:hover": {
      background: "#003366",
    },
    "&.Mui-disabled": {
      opacity: 0.5
    },
    "& img": {
      filter: "brightness(100) invert(0)",
      marginRight: 20,
    },
    "& .MuiTypography-root": {
      fontSize: theme.spacing(2),
      color: "#FFF!important",
      fontWeight: 700,
      textTransform: "none",
    },
  },
}));

const PrimaryButton = props => {
  const { onClick, title, icon, appendIcon = false, className, children, ...rest } = props;
  const classes = useStyles();

  return (
    <Button onClick={onClick} className={clsx(classes.button, className)} {...rest}>
      {children && children}
      {!children &&
        <>
          {!appendIcon && icon && <img src={icon} alt=":( Not Found" />}
          <Typography>{title}</Typography>
          {appendIcon && icon && <img src={icon} alt=":( Not Found" />}
        </>
      }
    </Button>
  );
};

export default PrimaryButton;
