import React from "react";
import {useSelector} from "react-redux";
import {Box, makeStyles, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import PatientCard from "pages/Patient/PatientCard";
import MobileHeader from "../navigation/MobileHeader";
import formatAddress from "../../utils/formatAddress";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 0,
        [theme.breakpoints.up("lg")]: {
            paddingTop: theme.spacing(2),
        },
    },
    title: {
        fontFamily: "Lato",
        fontWeight: 700,
        fontSize: 16,
        color: "#003366",
        paddingLeft: 0,
        margin: theme.spacing(3, 0, 2),
        [theme.breakpoints.up("lg")]: {
            margin: theme.spacing(4, 0, 3),
            paddingLeft: theme.spacing(3),
            fontSize: 20,
            color: "#454D5F",
        },
    },
    table: {
        border: "1px solid #DDE3EE",
        borderRadius: "8px",
        overflow: "hidden",
        "& > .MuiBox-root": {
            minHeight: 48,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            padding: "14px 24px",
            [theme.breakpoints.up("sm")]: {
                flexDirection: "row",
                alignItems: "center",
                padding: "0 24px",
            },
            "&:nth-of-type(odd)": {
                backgroundColor: "#F7F8FB",
            },
            "& .MuiTypography-root": {
                fontFamily: "Lato",
                "&:first-child": {
                    fontSize: 12,
                    color: "#454D5F",
                    marginBottom: 8,
                    textTransform: "uppercase",
                    [theme.breakpoints.up("sm")]: {
                        fontSize: 16,
                        marginBottom: 0,
                        textTransform: "none",
                    },
                },
                "&:last-child": {
                    fontSize: 16,
                    color: "#003366",
                    [theme.breakpoints.up("sm")]: {
                        fontSize: 20,
                    },
                },
            },
        },
    },
}));

const Personal = (props) => {
    const classes = useStyles();
    const {data} = useSelector((state) => state.patient);
    const {t} = useTranslation();
    const medicalData = [
        {
            name: t("pages.patient.personal.street"),
            value: formatAddress(data)
        },
        {
            name: t("pages.patient.personal.phone"),
            value: data?.phone_number,
        },
        {
            name: t("pages.patient.personal.email"),
            value: data?.email,
        },
    ];

    const contactData = [
        {
            name: t("pages.patient.personal.weight"),
            value: `${data?.weight} kg`,
        },
        {
            name: t("pages.patient.personal.height"),
            value: `${data?.height} cm`,
        },
        {
            name: t("pages.patient.personal.projectNumber"),
            value: data?.id,
        },
    ];

    return (
        <>
            <MobileHeader title="Dane osobowe i medyczne"/>
            <PatientCard active="personal" loadStatus={props.location?.state?.load}>
                <Box className={classes.root}>
                    <Typography className={classes.title}>
                        {t("pages.patient.personal.contactDetails")}
                    </Typography>
                    <Box className={classes.table}>
                        {medicalData.map((data, index) => {
                            return (
                                <Box key={index}>
                                    <Typography>{data.name}</Typography>
                                    <Typography>{data.value}</Typography>
                                </Box>
                            );
                        })}
                    </Box>
                    <Typography className={classes.title}>
                        {t("pages.patient.personal.medicalData")}
                    </Typography>
                    <Box className={classes.table}>
                        {contactData.map((data, index) => {
                            return (
                                <Box key={index}>
                                    <Typography>{data.name}</Typography>
                                    <Typography>{data.value}</Typography>
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
            </PatientCard>
        </>
    );
};

export default Personal;
