import React, {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Box, Button, makeStyles, Typography} from "@material-ui/core";
import CloseIcon from "assets/images/icons/close_md.svg";
import MenuIcon from "assets/images/icons/menu.svg";
import LogoutIcon from "assets/images/icons/logout.svg";
import KeyIcon from "assets/images/icons/key.svg";
import Routes from "router/Routes";
import Storage from "utils/Storage";
import BookmarkActiveImage from "assets/images/icons/active_bookmark.png";
import BookmarkNormalImage from "assets/images/icons/normal_bookmark.png";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "block",
        width: "100%",
        background: "#FFF",
        position: "relative",
        zIndex: 10,
        [theme.breakpoints.up("lg")]: {
            display: "none",
        },
    },
    header: {
        background: "#FFF",
        padding: "8px 16px",
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #DDE3EE",
    },
    hamburgerButton: {
        minWidth: 48,
        width: 48,
        height: 48,
        border: "1px solid #DDE3EE",
        borderRadius: 8,
        background: "#FFF",
        marginRight: 16,
    },
    title: {
        fontFamily: "Lato",
        fontSize: 16,
        fontWeight: 700,
        color: "#454D5F",
    },
    breadcrumb: {
        minHeight: 75,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "14px 16px",
        borderBottom: "1px solid #DDE3EE",
        "& .MuiTypography-root": {
            fontFamily: "Lato",
            fontWeight: 700,
            fontSize: 16,
            color: "#454D5F",
        },
    },
    isOpened: {
        transform: "translateX(0)!important",
    },
    body: {
        width: "100%",
        position: "absolute",
        background: "#FFF",
        minHeight: "calc(100vh - 140px)",
        transition: "0.3s",
        transform: "translateX(-100%)",
    },
    route: {
        minHeight: 55,
        padding: "0 24px",
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        "& .MuiTypography-root": {
            marginLeft: 16,
            fontFamily: "Lato",
            fontWeight: 700,
            fontSize: 16,
            color: "#454D5F",
        },
    },
    activeRoute: {
        background: "#E5F2FF",
        "& .MuiTypography-root": {
            color: "#003366",
        },
    },
}));

const MobileHeader = ({title}) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const handleOpen = () => {
        setIsOpen(!isOpen);
        document.body.style.overflow = "hidden";
    };

    const handleClose = () => {
        setIsOpen(false);
        document.body.style.overflow = "auto";
    };

    const routes = [
        {
            name: t("layout.personalMedicalData"),
            link: Routes.Dashboard.Patient.Personal,
        },
        {
            name: t("layout.MedicationTaken"),
            link: Routes.Dashboard.Patient.Medications,
        },
        {
            name: t("layout.DoctorComments"),
            link: Routes.Dashboard.Patient.DoctorComments,
        },
        {
            name: t("layout.Attachments"),
            link: Routes.Dashboard.Patient.Attachments,
        },
        {
            name: t("layout.Samples"),
            link: Routes.Dashboard.Patient.Probes,
        },
    ];

    const userInfo = JSON.parse(Storage.get("user"));

    return (
        <Box className={classes.root}>
            <Box className={classes.header}>
                <Button className={classes.hamburgerButton} onClick={handleOpen}>
                    {isOpen ? (
                        <img src={CloseIcon} alt=":( Not Found"/>
                    ) : (
                        <img src={MenuIcon} alt=":( Not Found"/>
                    )}
                </Button>
                <Typography className={classes.title}>{title}</Typography>
            </Box>
            <Box className={`${classes.body} ${isOpen ? classes.isOpened : ``}`}>
                <Box className={classes.breadcrumb}>
                    <Typography>
                        {`${userInfo?.patient?.surname} ${userInfo?.patient?.firstname}`}
                    </Typography>
                    <Box>
                        <Button>
                            <Link to={Routes.Dashboard.ChangePassword}>
                                <img src={KeyIcon} alt=":( Not Found"/>
                            </Link>
                        </Button>
                        <Button>
                            <Link to={Routes.Auth.Login}>
                                <img src={LogoutIcon} alt=":( Not Found"/>
                            </Link>
                        </Button>
                    </Box>
                </Box>
                <Box className={classes.routes}>
                    {routes.map((route, index) => {
                        return (
                            <Link
                                to={route.link}
                                key={index}
                                className={`${classes.route} ${
                                    route.link === location.pathname ? classes.activeRoute : ""
                                }`}
                                onClick={handleClose}
                            >
                                <Box>
                                    {location.pathname === route.link ? (
                                        <img src={BookmarkActiveImage} alt=":( Not Found"/>
                                    ) : (
                                        <img src={BookmarkNormalImage} alt=":( Not Found"/>
                                    )}
                                </Box>
                                <Typography>{route.name}</Typography>
                            </Link>
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
};

export default MobileHeader;
