import React, { useEffect, useState } from "react";
import { Button, Box, makeStyles, Typography, Card } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "store/actions/PatientAction";
import MessageModal from "components/modals/MessageModal";
import PatientCard from "pages/Patient/PatientCard";
import MobileHeader from "../navigation/MobileHeader";
import Moment from "react-moment";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 16,
    color: "#003366",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: theme.spacing(3, 0, 2),
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(3, 0),
      fontSize: 20,
      color: "#454D5F",
    },
    "& .MuiButton-root": {
      padding: "0 24px",
      height: 56,
      background: "#003366",
      borderRadius: 8,
      "& .MuiTypography-root": {
        fontSize: 16,
        fontWeight: 700,
        textTransform: "none",
        color: "#FFF",
      },
      "& img": {
        filter: "brightness(100) invert(0)",
        marginRight: 20,
      },
    },
  },
  card: {
    border: "1px solid #DDE3EE",
    borderRadius: 8,
    boxShadow: "none",
    marginBottom: 16,
    background: "#F7F8FB",
    [theme.breakpoints.up("lg")]: {
      background: "#FFF",
    },
  },
  cardHeader: {
    borderBottom: "1px solid #DDE3EE",
    padding: "18px 24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& img": {
      marginRight: 24,
    },
    [theme.breakpoints.up("lg")]: {
      padding: "18px 32px 15px",
      "& img": {
        marginRight: 30,
      },
    },
  },
  name: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 16,
    color: "#003366",
    marginBottom: 8,
  },
  time: {
    fontFamily: "Lato",
    fontSize: 14,
    color: "#6C7689",
  },
  cardBody: {
    padding: "16px 24px 24px",
    [theme.breakpoints.up("lg")]: {
      padding: 32,
    },
  },
  description: {
    fontFamily: "Lato",
    fontSize: 16,
    color: "#003366",
  },
  read: {
    border: '2px solid #9F302D',
    /*"& p": {
      color: 'black',
      fontWeight: 800,
    },*/
  },
  detailsButton: {
    width: "100%",
    height: 56,
    border: "2px solid #003366",
    backgroundColor: "#003366",
    color: "white",
    // fontSize: '16px',
    fontWeight: '600',
    borderRadius: 8,
    textTransform: "none",
    float: "right",
    "&:hover": {
      backgroundColor: "#003366",
    },
    "& .MuiTypography-root": {
      fontFamily: "Lato",
      fontWeight: 700,
      fontSize: 16,
      color: "#003366",
      /*marginLeft: 16*/
    },
    [theme.breakpoints.up("lg")]: {
      width: 217,
    },
  },
}));

const Messages = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const messages = useSelector((state) => state.patient.messageList);
  const [openModal, setOpenModal] = useState(false);
  const [editingData, setEditingData] = useState({});
  const [status, setStatus] = useState("");

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setEditingData({});
    setOpenModal(false);
  };

  const handleSave = (id) => {
    dispatch(Actions.readMessage(id));
  };

  return (
    <>
      <MobileHeader title="Komunikaty" />
      <PatientCard active="messages">
        <Box>
          <Typography className={classes.title}>
            {t("pages.patient.messages.title")}
          </Typography>
          {messages.length == 0 && <Typography align={"center"}>Brak komunikatów.</Typography>}
          {messages.map((message, index) => {
            let tempAry = message.created_at.split("T");
            let dateAry = tempAry[0].split("-");
            let dateVal = dateAry.reverse().join(".");
            let timeVal = tempAry[1].split(".")[0];

            const readClass = (message.read_at === null || message.read_at === '') ? ' ' + classes.read : '';
            return (
              <Card
                className={classes.card + readClass}
                key={index}
              >
                <Box className={classes.cardHeader}>
                  <Box>
                    <Typography className={classes.name}>{message.sender.doctor ? message.sender.doctor.firstname+" "+message.sender.doctor.surname : 'Administrator'}</Typography>
                    <Typography className={classes.time}>
                      {"Komunikat z dnia " + dateVal + ", wysłane o " + timeVal}
                    </Typography>
                    {message.read_at != null && <Typography className={classes.time}>
                      <strong>Odczytano <Moment format="DD.MM.YYYY HH:mm">{message.read_at}</Moment></strong>
                    </Typography>}
                  </Box>
                  <Box>
                    <Button
                      className={classes.detailsButton}
                      onClick={() => {
                        if (message.read_at === null || message.read_at === '') {
                          setStatus("edit");
                        } else {
                          setStatus("view");
                        }
                        setOpenModal(true);
                        setEditingData(message);
                      }}
                    >
                      Podgląd
                    </Button>
                  </Box>
                </Box>
              </Card>
            );
          })}
          <MessageModal
            openModal={openModal}
            onClose={handleCloseModal}
            data={editingData}
            onSave={handleSave}
            status={status}
          />
        </Box>
      </PatientCard>
    </>
  );
};

export default Messages;
