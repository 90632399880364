import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Card,
  Dialog,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import SampleImage from "assets/images/pages/patient/sample.png";
import CloseIcon from "assets/images/icons/close.svg";
import TrackIcon from "assets/images/icons/track.svg";

const useStyles = makeStyles((theme) => ({
  card: {
    border: "1px solid #DDE3EE",
    borderRadius: 8,
    padding: "20px 16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    boxShadow: "none",
    marginBottom: 24,
    background: "#F7F8FB",
    "& > .MuiBox-root": {
      marginBottom: 20,
      "& > img": {
        width: 29,
        marginRight: 30,
      },
      display: "flex",
      alignItems: "center",
      "& .MuiTypography-root": {
        fontFamily: "Lato",
        fontWeight: 700,
      },
    },
    [theme.breakpoints.up("lg")]: {
      background: "#FFF",
      padding: theme.spacing(3, 4),
      flexDirection: "row",
      alignItems: "center",
      "& > .MuiBox-root": {
        marginBottom: 0,
        "& > img": {
          width: "45px!important",
          marginRight: "38px!important",
        },
      },
    },
  },
  name: {
    fontSize: 20,
    color: "#003366",
    marginBottom: 8,
    [theme.breakpoints.up("lg")]: {
      fontSize: 24,
    },
  },
  date: {
    fontWeight: "400!important",
    fontSize: 14,
    color: "#454D5F",
    marginBottom: 0,
    [theme.breakpoints.up("lg")]: {
      fontSize: 16,
      fontWeight: "700!important",
      marginBottom: 8,
    },
  },
  description: {
    display: "none",
    fontSize: 16,
    color: "#003366",
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
  trackButton: {
    width: "100%",
    margin: "4px 0",
    height: 56,
    border: "2px solid #003366",
    borderRadius: 8,
    textTransform: "none",
    "& .MuiTypography-root": {
      fontFamily: "Lato",
      fontWeight: 700,
      fontSize: 16,
      color: "#003366",
      marginLeft: 16,
    },
    [theme.breakpoints.up("lg")]: {
      width: 217,
    },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      marginBottom: "0!important",
      width: "100%",
    },
  },
  dialog: {
    "& .MuiPaper-root": {
      width: 1118,
      maxWidth: 1118,
    },
    "& .MuiGrid-item": {
      marginBottom: theme.spacing(1),
    },
  },
  dialogHeader: {
    padding: "20px 32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiTypography-root": {
      fontFamily: "Lato",
      fontWeight: 700,
      fontSize: 20,
      color: "#003366",
    },
  },
  closeButton: {
    width: 32,
    height: 32,
    minWidth: 32,
    border: "1px solid #DDE3EE",
    borderRadius: 8,
  },
  dialogBody: {
    padding: theme.spacing(0, 4, 10),
  },
  table: {
    "& td": {
      fontSize: 20,
    },
  },
}));

const ProbeCollectTicket = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const rows = [
    {
      name: "Wyslanie przesylki",
      date: "2014-11-08 04:25",
      category: "WER Warszawa",
    },
    {
      name: "Wyslanie przesylki",
      date: "2014-11-08 04:25",
      category: "WER Warszawa",
    },
    {
      name: "Wyslanie przesylki",
      date: "2014-11-08 04:25",
      category: "WER Warszawa",
    },
    {
      name: "Wyslanie przesylki",
      date: "2014-11-08 04:25",
      category: "WER Warszawa",
    },
    {
      name: "Wyslanie przesylki",
      date: "2014-11-08 04:25",
      category: "WER Warszawa",
    },
    {
      name: "Wyslanie przesylki",
      date: "2014-11-08 04:25",
      category: "WER Warszawa",
    },
  ];

  return (
    <>
      <Card className={classes.card}>
        <Box>
          <img src={SampleImage} alt=":( Not Found" />
          <Box>
            <Typography className={classes.name}>
              Próbka_nr_{props.building}
            </Typography>
            <Typography className={classes.date}>
              Próbka pobrana i wysłana w dniu:{" "}
              {moment(props.date).format("DD.MM.YYYY")}
            </Typography>
            <Typography className={classes.description}>
              Wysłana do: Laboratorium sp. z o. o. ul. Ulica 32/1, 12-345 Łódź
            </Typography>
          </Box>
        </Box>
        <Box className={classes.buttonContainer}>
          <Button className={classes.trackButton} onClick={() => setOpen(true)}>
            <Typography>Pobierz etykietę</Typography>
          </Button>
          <Button className={classes.trackButton} onClick={() => setOpen(true)}>
            <img src={TrackIcon} alt=":( Not Found" />
            <Typography>{t("pages.patient.samples.track")}</Typography>
          </Button>
        </Box>
      </Card>
      <Dialog onClose={handleClose} className={classes.dialog} open={open}>
        <Box className={classes.dialogHeader}>
          <Typography>Śledź przesyłkę</Typography>
          <Button onClick={handleClose} className={classes.closeButton}>
            <img src={CloseIcon} alt=":( Not Found" />
          </Button>
        </Box>
        <Box className={classes.dialogBody}>
          <Table className={classes.table}>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.category}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Dialog>
    </>
  );
};

export default ProbeCollectTicket;
