import React, { useState } from "react";
import PatientCard from "pages/Patient/PatientCard";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Dropzone from "components/form/Dropzone";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FileImage from "assets/images/pages/patient/file.png";
import MobileHeader from "components/navigation/MobileHeader";
import CloseIcon from "assets/images/icons/close.svg";
import TrashIcon from "assets/images/icons/trash.svg";
import API from "apis/API";
import Storage from "utils/Storage";
import moment from "moment";
import * as Actions from "store/actions/PatientAction";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
    [theme.breakpoints.up("lg")]: {
      paddingTop: 40,
    },
  },
  title: {
    fontFamily: "Lato",
    fontSize: 20,
    fontWeight: 700,
    color: "#003366",
    margin: "24px 0 16px",
    [theme.breakpoints.up("lg")]: {
      margin: "0 0 47px",
      color: "#454D5F",
    },
  },
  dropzone: {
    marginBottom: 33,
  },
  group: {
    marginBottom: 8,
  },
  groupTitle: {
    fontFamily: "Lato",
    fontSize: 16,
    color: "#454D5F",
    marginBottom: 32,
  },
  file: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: 24,
    "& img": {
      width: 31,
      marginRight: 14,
    },
    [theme.breakpoints.up("lg")]: {
      "& img": {
        width: 62,
        marginRight: 24,
      },
    },
  },
  name: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 14,
    color: "#003366",
    textDecoration: "underline",
    marginBottom: 3,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8,
    },
  },
  date: {
    fontFamily: "Lato",
    fontSize: 14,
    color: "#6C7689",
    marginBottom: 16,
  },
  label: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 10,
    color: "#003366",
    marginBottom: 5,
  },
  comment: {
    fontFamily: "Lato",
    fontSize: 14,
    color: "#454D5F",
  },
  dialog: {
    "& .MuiPaper-root": {
      width: 870,
      maxWidth: 870,
      padding: "20px 32px",
    },
  },
  dialogHeader: {
    marginBottom: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiTypography-root": {
      fontFamily: "Lato",
      fontSize: 20,
      fontWeight: 700,
      color: "#003366",
    },
    "& .MuiButton-root": {
      minWidth: 32,
      width: 32,
      height: 32,
      background: "#FFF",
      border: "1px solid #DDE3EE",
      borderRadius: 8,
    },
  },
  dialogBody: {
    marginBottom: 20,
    "& .ck-content": {
      height: "350px!important",
    },
  },
  dialogFooter: {
    display: "flex",
    justifyContent: "flex-end",
    "& .MuiButton-root": {
      width: 140,
      height: 56,
      borderRadius: 8,
      fontFamily: "Lato",
      fontSize: 16,
      fontWeight: 700,
      textTransform: "none",
      marginLeft: theme.spacing(2),
      border: "1px solid #003366",
      "&:first-child": {
        color: "#003366",
      },
      "&:last-child": {
        color: "#FFF",
        background: "#003366",
      },
    },
  },
  fileContainer: {
    display: "flex",
  },
  deleteFile: {
    marginTop: 20,
    marginRight: 8,
    width: 24,
    height: 24,
    minWidth: 24,
    border: "1px solid #DDE3EE",
    background: "#F7F8FB",
    borderRadius: 6,
    "& img": {
      width: 12.5,
      height: 12.5,
    },
  },
}));

const Attachments = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState([]);
  const [comment, setComment] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const fileGroups = [
    {
      title: "Wizyta 2 - 23.10.2021",
      files: [
        {
          name: "EKG_Nowak_Katarzyna_82010200892.pdf",
          date: "23.10.2021, 13:04",
          comment:
            "Lorem Ipsum jest tekstem stosowanym jako przykładowy wypełniacz w przemyśle poligraficznym.",
        },
        {
          name: "EKG_Nowak_Katarzyna_82010200892.pdf",
          date: "23.10.2021, 13:04",
        },
        {
          name: "EKG_Nowak_Katarzyna_82010200892.pdf",
          date: "23.10.2021, 13:04",
        },
      ],
    },
    {
      title: "Wizyta 1 - 23.10.2021",
      files: [
        {
          name: "EKG_Nowak_Katarzyna_82010200892.pdf",
          date: "23.10.2021, 13:04",
          comment:
            "Lorem Ipsum jest tekstem stosowanym jako przykładowy wypełniacz w przemyśle poligraficznym.",
        },
      ],
    },
  ];

  const { patientID, files } = useSelector((state) => {
    return {
      files: state.patient.data?.files,
      patientID: JSON.parse(Storage.get("user"))?.patient?.id || null,
    };
  });

  const handleSelectFile = (selectedFile) => {
    setOpen(true);
    setFile(selectedFile);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteFile = (fileID) => {
    dispatch(Actions.deleteFile(fileID));
  };

  const handleDownloadFile = (fileID, filename) => {
    const blobInterceptor = API.interceptors.request.use(
      (config) => {
        config.responseType = "blob";
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
    API.patient.file.download(fileID).then((res) => {
      API.interceptors.request.eject(blobInterceptor);
      var fileURL = window.URL.createObjectURL(new Blob([res.data]));
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);
      fileLink.click();
    });
  };

  const handleSave = () => {
    let bodyFormData = new FormData();
    bodyFormData.append("file", file[0]);
    bodyFormData.append("comment", comment);
    bodyFormData.append("ref_type", "patient");
    bodyFormData.append("ref_id", patientID);
    bodyFormData.append("created_by", patientID);
    const formDataInterceptors = API.interceptors.request.use(
      (config) => {
        config.headers["Content-Type"] = "application/x-www-form-urlencoded";
        config.data = bodyFormData;
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
    setIsSaving(true);
    API.post("/files").then((res) => {
      API.interceptors.request.eject(formDataInterceptors);
      setIsSaving(false);
      setOpen(false);
      setFile([]);
      setComment("");
      dispatch(Actions.addAttachmentFile(res.data.file));
    });
  };

  return (
    <>
      <MobileHeader title="Komentarze lekarza" />
      <PatientCard active="attachments">
        <Box className={classes.root}>
          <Typography className={classes.title}>
            {t("pages.patient.attachments.title")}
          </Typography>
          <Box className={classes.dropzone}>
            <Dropzone
              accept=".xls, .xlsx, .pdf, .doc, .docx, .txt, .rtf"
              multiple={false}
              files={file}
              onChange={(files) => handleSelectFile(files)}
            />
          </Box>
          {files?.map((file, index) => {
            return (
              <Box className={classes.fileContainer}>
                {
                  file.created_by === patientID &&
                  <Button
                    className={classes.deleteFile}
                    onClick={() => handleDeleteFile(file.id)}
                  >
                    <img src={TrashIcon} alt=":( Not Found" />
                  </Button>
                }
                <Box className={classes.file} key={index}>
                  <img src={FileImage} alt=":( Not Found" />
                  <Box>
                    <Typography
                      className={classes.name}
                      onClick={() =>
                        handleDownloadFile(file.id, file.original_name)
                      }
                    >
                      {file.original_name}
                    </Typography>
                    <Typography className={classes.date}>
                      {t("pages.patient.attachments.added")}{" "}
                      {moment(file.updated_at).format("YYYY-MM-DD, hh:mm")}
                    </Typography>
                    {file.comment && (
                      <Box>
                        <Typography className={classes.label}>
                          {t("pages.patient.attachments.comment")}:
                        </Typography>
                        <Box
                          className={classes.comment}
                          dangerouslySetInnerHTML={{ __html: file.comment }}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            );
          })}
          {/* {fileGroups.map((group, gIndex) => {
            return (
              <Box className={classes.group} key={gIndex}>
                <Typography className={classes.groupTitle}>
                  {group.title}
                </Typography>
                {group.files.map((file, fIndex) => {
                  return (
                    <Box className={classes.file} key={fIndex}>
                      <img src={FileImage} alt=":( Not Found" />
                      <Box>
                        <Typography className={classes.name}>
                          {file.name}
                        </Typography>
                        <Typography className={classes.date}>
                          {t("pages.patient.attachments.added")} {file.date}
                        </Typography>
                        {file.comment && (
                          <Box>
                            <Typography className={classes.label}>
                              {t("pages.patient.attachments.comment")}:
                            </Typography>
                            <Typography className={classes.comment}>
                              {file.comment}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            );
          })} */}
        </Box>
        <Dialog open={open} onClose={handleClose} className={classes.dialog}>
          <Box className={classes.dialogHeader}>
            <Typography>{t("common.newNote")}</Typography>
            <Button onClick={handleClose}>
              <img src={CloseIcon} alt=":( Not Found" />
            </Button>
          </Box>
          <Box className={classes.dialogBody}>
            <CKEditor
              editor={ClassicEditor}
              data={comment}
              onChange={(event, editor) => {
                const data = editor.getData();
                setComment(data);
              }}
            />
          </Box>
          <Box className={classes.dialogFooter}>
            <Button onClick={handleClose}>{t("common.cancel")}</Button>
            <Button onClick={handleSave}>
              {isSaving ? (
                <CircularProgress size={30} style={{ color: "#FFF" }} />
              ) : (
                <>{t("common.add")}</>
              )}
            </Button>
          </Box>
        </Dialog>
      </PatientCard>
    </>
  );
};

export default Attachments;
