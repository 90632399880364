import React from "react";
import { useSelector } from "react-redux";
import PatientCard from "pages/Patient/PatientCard";
import { useTranslation } from "react-i18next";
import { Box, Card, makeStyles, Typography } from "@material-ui/core";
import PillImage from "assets/images/pages/patient/pill.png";
import MobileHeader from "../navigation/MobileHeader";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 16,
    color: "#003366",
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(3, 0, 2),
      fontSize: 20,
      color: "#454D5F",
    },
  },
  card: {
    boxShadow: "none",
    border: "1px solid #DDE3EE",
    borderRadius: 8,
    marginBottom: 24,
    padding: "18px 24px",
    display: "flex",
    alignItems: "center",
    background: "#F7F8FB",
    "& img": {
      width: 36,
      marginRight: 24,
    },
    [theme.breakpoints.up("lg")]: {
      background: "#FFF",
      padding: "36px 32px",
      "& img": {
        width: 51,
        marginRight: 32,
      },
    },
  },
  name: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 20,
    color: "#003366",
    marginBottom: 8,
    [theme.breakpoints.up("lg")]: {
      fontSize: 24,
      marginBottom: 16,
    },
  },
  dose: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 14,
    color: "#454D5F",
    [theme.breakpoints.up("lg")]: {
      fontSize: 16,
    },
  },
}));

const Medications = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { additional_medicines, medicines, patient_inclusion_criteria_sub_group } = useSelector((state) => {
    return {
      additional_medicines: state.patient?.data?.additional_medicines,
      medicines: state.patient?.data?.medicines,
      patient_inclusion_criteria_sub_group : state.patient?.data?.patient_inclusion_criteria_sub_group
    };
  });

  console.log(additional_medicines, medicines);

  return (
    <>
      <MobileHeader title="Przyjmowane leki" />
      <PatientCard active="medications">
        <Box>
          <Typography className={classes.title}>
            {t("pages.patient.medications.title")}
          </Typography>
          {medicines?.map((row, index) => {
            return (
              <Card className={classes.card} key={index}>
                <img src={PillImage} alt=":( Not Found" />
                <Box>
                  <Typography className={classes.name}>
                    {row.medicine.name}
                  </Typography>
                  <Typography className={classes.dose}>
                    {t("pages.patient.medications.dose")}: {row.dose} mg /{" "}
                    {t("pages.patient.medications.time")}
                  </Typography>
                </Box>
              </Card>
            );
          })}
          {additional_medicines?.map((row, index) => {
            return (
              <Card className={classes.card} key={index}>
                <img src={PillImage} alt=":( Not Found" />
                <Box>
                  <Typography className={classes.name}>{row.name}</Typography>
                  <Typography className={classes.dose}>
                    {t("pages.patient.medications.dose")}: {row.dose} mg /{" "}
                    {t("pages.patient.medications.time")}
                  </Typography>
                </Box>
              </Card>
            );
          })}
          {patient_inclusion_criteria_sub_group && patient_inclusion_criteria_sub_group.subgroup && (
              <Card className={classes.card}>

                  <img src={PillImage} alt=":( Not Found" />
                  <Box>
                    <Typography className={classes.name}>
                      {patient_inclusion_criteria_sub_group.subgroup?.medicines}
                    </Typography>
                    <Typography className={classes.dose}>
                      {t("pages.patient.medications.dose")}:{" "}
                      {patient_inclusion_criteria_sub_group.dose} mg /{" "}
                      {t("pages.patient.medications.time")}
                    </Typography>
                  </Box>

              </Card>
          )}
        </Box>
      </PatientCard>
    </>
  );
};

export default Medications;
