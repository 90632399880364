import React from "react";
import { Box, makeStyles, TextField, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  label: {
    fontFamily: "Lato",
    fontSize: 14,
    color: "#454D5F",
    marginBottom: theme.spacing(1),
  },
  textinput: {
    background: "#FFF",
    height: 56,
    "& .MuiInputBase-input": {
      fontFamily: "Lato",
      fontSize: 16,
      color: "#22272F",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #A6AEBF",
      borderRadius: 8,
    },
  },
}));

const TextInput = ({ label, ...rest }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography className={classes.label}>{label}</Typography>
      <TextField
        className={classes.textinput}
        variant="outlined"
        fullWidth
        {...rest}
      />
    </Box>
  );
};

export default TextInput;
