import axios from "axios";
import Storage from "utils/Storage";
import Routes from "router/Routes";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT_URI,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

API.interceptors.request.use(
  (config) => {
    const token = Storage.get("token");
    if (token && token != "undefined") config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  function (response) {
    return response;
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      if (error.response?.data.error === "Expired Token") {
        API.auth.refreshToken();
      } else {
        window.location.href = Routes.Auth.Login;
      }
    }
    return Promise.reject(error);
  }
);

API.auth = {
  login: (data) => API.post(`/auth/login/patient`, data),
};

API.patient = {
  getData: (data) => API.get(`/patients/${data}`),
  getDataBasic: (data) => API.get(`/patients/${data}/basic`),
  getDataProbes: (data) => API.get(`/patients/${data}/probes`),
  getDataVisits: (data) => API.get(`/patients/${data}/visits`),
  file: {
    delete: (data) => API.delete(`/files/${data}`),
    download: (data) => API.get(`/files/${data}`),
  },
  note: {
    delete: (data) => API.delete(`/patients/notes/${data}`),
  },
  visit: {
    getDataBySelectedDate: (date, data) =>
      API.get(`/visits?date=${date}`, { params: data }),
    getVisitCount: (data) =>
      API.get(`/visits/count?year=${data.year}&month=${data.month}`),
    create: (data) => API.post(`/visits`, data),
    delete: (data) => API.delete(`/visits/${data}`),
    update: (id, data) => API.put(`/visits/${id}`, data),
    show: id => API.get(`/visits/patient/${id}`)
  },
  probe: {
    addSet: (data) => API.post(`/probes/probe_set`, data),
    addCollect: (data) => API.post(`/probes/probe_collect`, data),
    show: id => API.get(`/probes/probe_detail/${id}`)
  },
};

API.doctor = {
  getList: () => API.get(`/doctors/all`),
};

API.settings = {
  documents: () => API.get(`/settings/documents/patient`),
  phone: {
    show: () => API.get('/settings/phone'),
  }
};

API.message = {
  getList: () => API.get(`/notifications/receive`),
  readMessage: (id) => API.put(`/notifications/read/${id}`),
};
API.changePassword = (data) => API.put('/change_password', data);

export default API;
