import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Box, Button, Card, LinearProgress, makeStyles, Typography} from "@material-ui/core";
import CreateAppointmentModal from "components/modals/CreateAppointmentModal";
// import UploadIcon from "assets/images/icons/upload.svg";
import ClockPlusIcon from "assets/images/icons/time-add.svg";
import * as Actions from "store/actions/PatientAction";
import Storage from "utils/Storage";
import Routes from "router/Routes";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        paddingBottom: theme.spacing(7),
        "& > .MuiCard-root": {
            width: "100%",
            minHeight: "100vh",
        },
        [theme.breakpoints.up("lg")]: {
            padding: theme.spacing(4),
            "& .MuiCard-root": {
                width: "100%",
                maxWidth: 1017,
            },
        },
    },
    card: {
        boxShadow: "none",
        [theme.breakpoints.up("lg")]: {
            border: "1px solid #DDE3EE",
            borderRadius: 8,
        },
    },
    cardHeader: {
        display: "none",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(6),
        borderBottom: "1px solid #DDE3EE",
        [theme.breakpoints.up("lg")]: {
            display: "flex",
        },
    },
    title: {
        fontFamily: "Lato",
        fontWeight: 700,
        fontSize: 32,
        color: "#003366",
        letterSpacing: "-2px",
        marginBottom: theme.spacing(2),
    },
    pesel: {
        fontFamily: "Lato",
        fontSize: 20,
        color: "#6C7689",
    },
    toolbar: {
        display: "flex",
    },
    uploadButton: {
        width: 60,
        minWidth: 60,
        height: 56,
        borderRadius: 8,
        background: "#6C7689",
        "&:hover": {
            background: "#6C7689",
        },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: theme.spacing(2),
    },
    editButton: {
        width: 176,
        height: 56,
        borderRadius: 8,
        textTransform: "none",
        border: "2px solid #003366",
        background: "#FFF",
        fontFamily: "Lato",
        fontWeight: 700,
        fontSize: 16,
        color: "#003366",
        marginRight: theme.spacing(3),
    },
    appointmentButton: {
        width: 178,
        height: 56,
        textTransform: "none",
        borderRadius: 8,
        background: "#003366",
        "&:hover": {
            background: "#003366",
        },
        "& .MuiTypography-root": {
            marginLeft: theme.spacing(2),
            fontFamily: "Lato",
            fontWeight: 700,
            fontSize: 16,
            color: "#FFF",
        },
    },
    cardBody: {
        padding: theme.spacing(0, 2, 3),
        [theme.breakpoints.up("lg")]: {
            padding: theme.spacing(2, 6, 8, 6),
        },
    },
    tab: {
        height: 56,
        padding: "0 20px",
        fontFamily: "Lato",
        fontSize: 16,
        color: "#6C7689",
        textTransform: "none",
        letterSpacing: "-1px",
        borderRadius: 8,
        display: "none",
        [theme.breakpoints.up("lg")]: {
            display: "inline-flex",
        },
    },
    activeTab: {
        background: "#E5F2FF",
        fontWeight: 700,
        color: "#0066CC",
    },
    badge: {
        minWidth: 30,
        height: 30,
        display: 'flex',
        borderRadius: 30,
        backgroundColor: 'red',
        color: 'white',
        marginLeft: 10,
        alignItems: 'center',
        justifyContent: 'center',
    }
}));

const PatientCard = (props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const {user, data, isLoading} = useSelector((state) => {
        return {
            user: state.auth.user,
            data: state.patient.data,
            isLoading: state.patient.isLoading,
        };
    });
    const unreadNum = useSelector((state) => state.patient.unreadMessageCount);
    const tabs = {
        personal: {
            name: t("pages.patient.layout.personalMedicalData"),
            link: Routes.Dashboard.Patient.Personal,
        },
        medications: {
            name: t("pages.patient.layout.medicationTaken"),
            link: Routes.Dashboard.Patient.Medications,
        },
        visits: {
            name: t("pages.patient.layout.visits"),
            link: Routes.Dashboard.Patient.Visits,
        },
        comments: {
            name: t("pages.patient.layout.doctorComments"),
            link: Routes.Dashboard.Patient.DoctorComments,
        },
        attachments: {
            name: t("pages.patient.layout.attachments"),
            link: Routes.Dashboard.Patient.Attachments,
        },
        probes: {
            name: t("pages.patient.layout.samples"),
            link: Routes.Dashboard.Patient.Probes,
        },
        messages: {
            name: t("pages.patient.layout.messages"),
            link: Routes.Dashboard.Patient.Messages,
        },
    };

    const handleClose = () => {
        dispatch(Actions.deleteSelectedVisit());
        setOpen(false);
    };

    useEffect(() => {
        console.log(props.loadStatus);
        if (props.loadStatus) {
            dispatch(
                Actions.getPatientBasicData(
                    user?.patient?.id || JSON.parse(Storage.get("user")).patient.id
                )
            );
        }
        dispatch(Actions.getDoctorList());
        dispatch(Actions.getMessagesList());
    }, []);

    return (
        <>
            { isLoading && <LinearProgress /> }
            { !isLoading && <>
                    <Box className={classes.root}>
                        {data && (
                            <Card className={classes.card}>
                                <Box className={classes.cardHeader}>
                                    <Box>
                                        <Typography className={classes.title}>
                                            {data.surname} {data.firstname}
                                        </Typography>
                                        <Typography className={classes.pesel}>
                                            PESEL: {data.pesel}
                                        </Typography>
                                    </Box>
                                    <Box className={classes.toolbar}>
                                        <Button
                                            className={classes.appointmentButton}
                                            onClick={() => setOpen(true)}
                                        >
                                            <img src={ClockPlusIcon} alt=":( Not Found"/>
                                            <Typography>{t("pages.patient.layout.appointment")}</Typography>
                                        </Button>
                                    </Box>
                                </Box>
                                <Box className={classes.cardBody}>
                                    <Box>
                                        {Object.keys(tabs).map((key) => {
                                            const tab = tabs[key];
                                            return (
                                                <Link
                                                    to={tab.link}
                                                    style={{textDecoration: "none"}}
                                                    key={key}
                                                >
                                                    <Button
                                                        className={`${classes.tab} ${
                                                            props.active === key ? classes.activeTab : ``
                                                        }`}
                                                        key={key}
                                                    >
                                                        {tab.name}
                                                        {unreadNum > 0 && key === 'messages' && <span className={classes.badge}>{unreadNum}</span>}
                                                    </Button>
                                                </Link>
                                            );
                                        })}
                                    </Box>
                                    {props.children}
                                </Box>
                            </Card>
                        )}
                        <CreateAppointmentModal open={open} handleClose={handleClose}/>
                    </Box>
                </>
            }
        </>
    );
};

export default PatientCard;
