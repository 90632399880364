import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import Routes from "router/Routes";
import { ReactComponent as BookmarkActiveImage } from "assets/images/icons/bookmark_active.svg";
import { ReactComponent as BookmarkNormalImage } from "assets/images/icons/bookmark_normal.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    "& a": {
      textDecoration: "none",
    },
    "& .MuiListItem-root": {
      height: 55,
      borderRadius: 8,
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 3),
      "& .MuiListItemIcon-root": {
        minWidth: "auto",
        marginRight: theme.spacing(2),
      },
      "& .MuiTypography-root": {
        fontFamily: "Lato",
        fontWeight: 700,
        fontSize: 14,
        color: "#454D5F",
      },
    },
  },
  active: {
    background: "#E5F2FF",
    "& .MuiTypography-root": {
      color: "#0066CC!important",
    },
  },
}));

const Sidebar = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const navLinks = [
    {
      name: t("layout.patientCard"),
      link: "/patient",
    },
    {
      name: t("layout.document"),
      link: Routes.Dashboard.Documents,
    },
  ];

  return (
    <List component="nav" className={classes.root}>
      {navLinks.map((link, index) => {
        const isMatch = location.pathname.indexOf(link.link) > -1;
        return (
          <Link key={index} to={link.link}>
            <ListItem button className={isMatch ? classes.active : ``}>
              <ListItemIcon>
                {isMatch ? <BookmarkActiveImage /> : <BookmarkNormalImage />}
              </ListItemIcon>
              <ListItemText>{link.name}</ListItemText>
            </ListItem>
          </Link>
        );
      })}
    </List>
  );
};

export default Sidebar;
