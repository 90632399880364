import React, { useState, useEffect } from "react";
import {useSelector, useDispatch } from "react-redux";
import {
    Box, LinearProgress, Button, makeStyles, Typography
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import PatientCard from "pages/Patient/PatientCard";
import ProbeTicket from "./Tickets/ProbeTicket";
import ProbeSetTicket from "./Tickets/ProbeSetTicket";
import ProbeCollectTicket from "./Tickets/ProbeCollectTicket";
import OrderSetModal from "../../modals/OrderSetModal";
import OrderCollectModal from "../../modals/OrderCollectModal";
import MobileHeader from "components/navigation/MobileHeader";
import Storage from "utils/Storage";
import * as Actions from "store/actions/PatientAction";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        minHeight: "100vh",
        paddingBottom: 20,
        [theme.breakpoints.up("lg")]: {
            minHeight: "auto",
        },
    },
    title: {
        fontFamily: "Lato",
        fontWeight: 700,
        fontSize: 16,
        color: "#454D5F",
        margin: theme.spacing(3, 0, 2),
        [theme.breakpoints.up("lg")]: {
            margin: theme.spacing(3, 0),
            fontSize: 20,
        },
    },
    card: {
        border: "1px solid #DDE3EE",
        borderRadius: 8,
        padding: "20px 16px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
        boxShadow: "none",
        marginBottom: 24,
        background: "#F7F8FB",
        "& > .MuiBox-root": {
            marginBottom: 20,
            "& img": {
                width: 29,
                marginRight: 30,
            },
            display: "flex",
            alignItems: "center",
            "& .MuiTypography-root": {
                fontFamily: "Lato",
                fontWeight: 700,
            },
        },
        [theme.breakpoints.up("lg")]: {
            background: "#FFF",
            padding: theme.spacing(3, 4),
            flexDirection: "row",
            alignItems: "center",
            "& > .MuiBox-root": {
                marginBottom: 0,
                "& img": {
                    width: "45px!important",
                    marginRight: "38px!important",
                },
            },
        },
    },
    name: {
        fontSize: 20,
        color: "#003366",
        marginBottom: 8,
        [theme.breakpoints.up("lg")]: {
            fontSize: 24,
        },
    },
    date: {
        fontWeight: "400!important",
        fontSize: 14,
        color: "#454D5F",
        marginBottom: 0,
        [theme.breakpoints.up("lg")]: {
            fontSize: 16,
            fontWeight: "700!important",
            marginBottom: 8,
        },
    },
    description: {
        display: "none",
        fontSize: 16,
        color: "#003366",
        [theme.breakpoints.up("lg")]: {
            display: "block",
        },
    },
    trackButton: {
        width: "100%",
        height: 56,
        border: "2px solid #003366",
        borderRadius: 8,
        textTransform: "none",
        "& .MuiTypography-root": {
            fontFamily: "Lato",
            fontWeight: 700,
            fontSize: 16,
            color: "#003366",
            marginLeft: 16,
        },
        [theme.breakpoints.up("lg")]: {
            width: 217,
        },
    },
    buttonContainer: {
        alignSelf: "flex-end",
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up("lg")]: {
            position: "relative",
            left: "auto",
            right: "auto",
            width: 320,
            bottom: "auto",
        },
    },
    orderButton: {
        height: 56,
        background: "#003366",
        marginBottom: 10,
        borderRadius: 8,
        fontFamily: "Lato",
        fontSize: 16,
        fontWeight: 700,
        color: "#FFF",
        padding: "0 34px",
        textTransform: "none",
        width: "100%",
        "&:hover": {
            background: "#003366",
        },
    },
    marginLinear: {
        marginTop: 20,
    }
}));

const Probes = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {probes, patient_sets_orders, probe_collects} = useSelector(
        (state) => state.patient?.data || []
    );
    const isTabLoading = useSelector((state) => state.patient?.isTabLoading);
    const {user} = useSelector((state) => state.auth.user);
    const classes = useStyles();
    const [isOpenOrderSetModal, setIsOpenOrderSetModal] = useState(false);
    const [isOpenOrderCollectModal, setIsOpenOrderCollectModal] = useState(false);

    useEffect(() => {
        dispatch(Actions.getPatientProbes(user?.patient?.id || JSON.parse(Storage.get("user")).patient.id));
    }, []);

    const handleCloseOrderSetModal = () => {
        setIsOpenOrderSetModal(false);
    };

    const handleCloseOrderCollectModal = () => {
        setIsOpenOrderCollectModal(false);
    };

    const handleOrderSet = () => {
        setIsOpenOrderSetModal(true);
    };

    const handleOrderCollect = () => {
        setIsOpenOrderCollectModal(true);
    };

    return (
        <>
            <MobileHeader title="Próbki"/>
            <PatientCard active="probes">
                {isTabLoading && <LinearProgress className={classes.marginLinear} />}
                {!isTabLoading && <>
                    <Box className={classes.root}>
                        <Typography className={classes.title}>
                            {t("pages.patient.probesList.title")}
                        </Typography>
                        <Box className={classes.buttonContainer}>
                            <Button className={classes.orderButton} onClick={handleOrderSet}>
                                {t("pages.patient.probesList.add")}
                            </Button>
                            <Button
                                className={classes.orderButton}
                                onClick={handleOrderCollect}
                            >
                                {t("pages.patient.probesList.order")}
                            </Button>
                        </Box>
                        {probes?.map((sample, index) => {
                            return <ProbeTicket {...sample} key={index}/>;
                        })}
                        {patient_sets_orders?.map((sample, index) => {
                            return <ProbeSetTicket {...sample} key={index}/>;
                        })}
                        {probe_collects?.map((sample, index) => {
                        // return <ProbeCollectTicket {...sample} key={index}/>;
                        })}
                    </Box>
                </>}
                <OrderSetModal
                    open={isOpenOrderSetModal}
                    onClose={handleCloseOrderSetModal}
                />
                <OrderCollectModal
                    open={isOpenOrderCollectModal}
                    onClose={handleCloseOrderCollectModal}
                />
            </PatientCard>
        </>
    );
};

export default Probes;
