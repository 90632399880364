import React from "react";
import { makeStyles, Modal as MuiModal, Backdrop, Fade, Card, Typography, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CloseIcon from "assets/images/icons/close.svg";
import IconButton from "components/buttons/IconButton";
import FormInput from "components/form/FormInput";
import PrimaryButton from "components/buttons/PrimaryButton";
import DefaultButton from "components/buttons/DefaultButton";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    padding: "25px",
    width: "500px",
  },
  title: {
    fontWeight: "bold",
    fontSize: 20,
    lineHeight: 1.4,
    color: "#003366"
  },
  subTitle: {
    fontWeight: "bold",
    color: "#454D5F",
    marginBottom: theme.spacing(1)
  },
}));

const Modal = props => {
  const { openModal, onClose, data, onSave, status } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const handleSave = () => {
    onSave(data.id);
    onClose();
  }

  return (
    <MuiModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModal}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Card className={classes.paper}>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography className={classes.title}>{t(`pages.patient.messages.view`)}</Typography>
            <IconButton icon={CloseIcon} onClick={onClose} />
          </Box>

          <FormInput
            name="content"
            title={t('pages.patient.messages.content')}
            value={data?.content}
            multiline={true}
            minRows={6}
            required={false}
            readonly={true}
          />

          <Box display="flex" justifyContent="flex-end">
            {status != 'view' && <PrimaryButton
              title={t('common.confirm_read')}
              onClick={handleSave}
              disabled={status === 'view'}
            />}
          </Box>
        </Card>
      </Fade>
    </MuiModal>
  );
};

export default Modal;
