import API from "apis/API";

export const getPatientData = (param) => async (dispatch) => {
  API.patient.getData(param).then((res) => {
    dispatch({ type: "GET_PATIENT_DATQA", payload: res.data.data });
  });
};


export const getPatientBasicData = (param) => async (dispatch) => {
  dispatch({type: "SET_LOADING", payload: true});
  API.patient.getDataBasic(param).then((res) => {
    dispatch({type: "GET_PATIENT_DATQA", payload: res.data.data});
    dispatch({type: "SET_LOADING", payload: false});
  });
};

export const getPatientProbes = (param) => async (dispatch) => {
  dispatch({type: "SET_TAB_LOADING", payload: true});
  API.patient.getDataProbes(param).then((res) => {
    dispatch({type: "GET_PATIENT_PROBES", payload: res.data.data});
    dispatch({type: "SET_TAB_LOADING", payload: false});
  });
}

export const getPatientVisits = (param) => async (dispatch) => {
  dispatch({type: "SET_TAB_LOADING", payload: true});
  API.patient.getDataVisits(param).then((res) => {
    dispatch({type: "GET_PATIENT_VISITS", payload: res.data.data});
    dispatch({type: "SET_TAB_LOADING", payload: false});
  });
}

export const deleteFutureVisit = (data) => async (dispatch) => {
  dispatch({ type: "DELETE_FUTURE_VISIT", payload: data });
};

export const updateNote = (data) => async (dispatch) => {
  dispatch({ type: "UPDATE_DOCTOR_NOTE", payload: data });
};

export const deleteNote = (data) => async (dispatch) => {
  dispatch({ type: "DELETE_DOCTOR_NOTE", payload: data });
};

export const deleteFile = (data) => async (dispatch) => {
  API.patient.file.delete(data).then((res) => {
    dispatch({ type: "REMOVE_ATTACHMENT_FILE", payload: data });
  });
};

export const addAttachmentFile = (data) => async (dispatch) => {
  dispatch({ type: "ADD_ATTACHMENT_FILE", payload: data });
};

export const getVisitsBySelectedDate = (data) => async (dispatch) => {
  API.patient.visit
    .getDataBySelectedDate(data.date, { doctor_id: data.doctor_id })
    .then((res) => {
      dispatch({ type: "GET_VISITS_BY_SELECTED_DATE", payload: res.data });
    });
};

export const addFutureVisits = (data) => async (dispatch) => {
  dispatch({ type: "ADD_FUTURE_VISITS", payload: data });
};

export const updateFutureVisits = (data) => async (dispatch) => {
  dispatch({ type: "UPDATE_FUTURE_VISITS", payload: data });
};

export const getDoctorList = () => async (dispatch) => {
  API.doctor.getList().then((res) => {
    dispatch({ type: "GET_DOCTOR_LIST", payload: res.data.data });
  });
};

export const selectVisit = (data) => async (dispatch) => {
  dispatch({ type: "SELECT_VISIT", payload: data });
};

export const deleteSelectedVisit = () => async (dispatch) => {
  dispatch({ type: "DELETE_SELECTED_VISIT" });
};

export const addOrderSetData = (data) => async (dispatch) => {
  dispatch({ type: "ADD_ORDER_SET_DATA", payload: data });
};

export const addOrderCollectData = (data) => async (dispatch) => {
  dispatch({ type: "ADD_ORDER_COLLECT_DATA", payload: data });
};

export const getMessagesList = (params) => async (dispatch) => {
  API.message.getList(params).then((res) => {
    dispatch({type: "GET_MESSAGES_LIST", payload: res.data});
  });
};

export const readMessage = (id) => async (dispatch) => {
  API.message.readMessage(id).then((res) => {
    dispatch({type: "READ_MESSAGE", payload: res.data.data});
  });
};

export const setSubTab = data => async dispatch => {
  dispatch({type: "SET_SELECTED_SUB_TAB", payload: data});
};