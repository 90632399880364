import React from "react";
import clsx from "clsx";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 32,
    fontWeight: 700,
    color: "#003366",
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
  },
}));

const Title = props => {
  const { children, className, ...rest } = props;
  const classes = useStyles();

  return (
    <Typography className={clsx(classes.title, className)} {...rest}>{children}</Typography>
  );
};

export default Title;
