import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, makeStyles, Typography } from "@material-ui/core";
import Routes from "router/Routes";
import CreateAppointmentModal from "components/modals/CreateAppointmentModal";
import ClockPlusIcon from "assets/images/icons/time-add.svg";
import BadgeActiveIcon from "assets/images/icons/mobile/badge_active.svg";
import BadgeNormalIcon from "assets/images/icons/mobile/badge_normal.svg";
import BellActiveIcon from "assets/images/icons/mobile/bell_active.svg";
import BellNormalIcon from "assets/images/icons/mobile/bell_normal.svg";
import CalendarActiveIcon from "assets/images/icons/mobile/calendar_active.svg";
import CalendarNormalIcon from "assets/images/icons/mobile/calendar_normal.svg";
import DocumentActiveIcon from "assets/images/icons/mobile/document_active.svg";
import DocumentNormalIcon from "assets/images/icons/mobile/document_normal.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 76,
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 999,
    background: "#F7F8FB",
    border: "1px solid #DDE3EE",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 20px",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  item: {
    minWidth: 52,
    position: "relative",
    "& > .MuiBox-root": {
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& .MuiTypography-root": {
        fontSize: 10,
        fontWeight: 700,
        color: "#003366",
        marginTop: 7,
      },
    },
  },
  modalButton: {
    "& .MuiBox-root": {
      width: 84,
      height: 84,
      background: "#0066CC",
      position: "absolute",
      top: -14,
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .MuiTypography-root": {
        color: "#FFF!important",
      },
    },
  },
}));

const MobileNavbar = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const routes = [
    {
      id: "card",
      name: t("layout.card"),
      link: Routes.Dashboard.Patient.Personal,
      action: () => {
        history.push(Routes.Dashboard.Patient.Personal);
        setOpen(false);
      },
      activeImage: BadgeActiveIcon,
      normalImage: BadgeNormalIcon,
    },
    {
      id: "documents",
      name: t("layout.document"),
      link: Routes.Dashboard.Documents,
      action: () => {
        history.push(Routes.Dashboard.Documents);
        setOpen(false);
      },
      activeImage: DocumentActiveIcon,
      normalImage: DocumentNormalIcon,
    },
    {
      id: "appointment",
      name: t("layout.appointment"),
      action: () => {
        setOpen(true);
      },
      activeImage: ClockPlusIcon,
      normalImage: ClockPlusIcon,
    },
    {
      id: "visits",
      name: t("layout.visits"),
      link: Routes.Dashboard.Patient.Visits,
      action: () => {
        history.push(Routes.Dashboard.Patient.Visits);
        setOpen(false);
      },
      activeImage: CalendarActiveIcon,
      normalImage: CalendarNormalIcon,
    },
    {
      id: "messages",
      name: t("layout.messages"),
      link: Routes.Dashboard.Patient.Messages,
      action: () => {
        history.push(Routes.Dashboard.Patient.Messages);
        setOpen(false);
      },
      activeImage: BellActiveIcon,
      normalImage: BellNormalIcon,
    },
  ];

  return (
    <Box className={classes.root}>
      {routes.map((route) => {
        return (
          <Box
            key={route.id}
            className={`${classes.item} ${
              route.id === "appointment" ? classes.modalButton : ``
            }`}
          >
            <Box onClick={route.action}>
              {route.link === location.pathname ? (
                <img src={route.activeImage} alt=":( Not Found" />
              ) : (
                <img src={route.normalImage} alt=":( Not Found" />
              )}
              <Typography
                style={{
                  color:
                    route.link === location.pathname ? "#0066CC" : "#003366",
                }}
              >
                {route.name}
              </Typography>
            </Box>
          </Box>
        );
      })}
      <CreateAppointmentModal open={open} handleClose={handleClose} />
    </Box>
  );
};

export default MobileNavbar;
