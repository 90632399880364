import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    Fab,
    Grid,
    makeStyles,
    MenuItem,
    Modal,
    Select,
    Typography
} from "@material-ui/core";
import LogoutIcon from "assets/images/icons/logout.svg";
import KeyIcon from "assets/images/icons/key.svg";
import Phone from "assets/images/icons/phone.svg";
import Sidebar from "./Sidebar";
import MobileNavbar from "components/navigation/MobileNavbar";
import Routes from "router/Routes";
import Storage from "utils/Storage";
import CloseIcon from "../assets/images/icons/close.svg";
import TextInput from "../components/form/TextInput";
import API from "apis/API";

const useStyles = makeStyles((theme) => ({
    root: {
        background: "#F7F8FB",
        minHeight: "100vh",
    },
    topbar: {
        display: "none",
        position: "fixed",
        top: 0,
        width: "100%",
        height: 84,
        background: "#FFF",
        borderBottom: "1px solid #DDE3EE",
        zIndex: 1,
        padding: theme.spacing(0, 4),
        [theme.breakpoints.up("lg")]: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
        },
    },
    logout: {
        width: 236,
        height: 56,
        textTransform: "none",
        border: "1px solid #9F302D",
        borderRadius: 8,
        "& .MuiTypography-root": {
            fontFamily: "Lato",
            fontSize: 16,
            fontWeight: 700,
            color: "#9F302D",
            marginLeft: theme.spacing(1),
        },
    },
    changePassword: {
        width: 236,
        height: 56,
        textTransform: "none",
        border: "1px solid #F1F3F8",
        background: "#F1F3F8",
        borderRadius: 8,
        marginRight: 10,
        "& .MuiTypography-root": {
            fontFamily: "Lato",
            fontSize: 16,
            fontWeight: 700,
            color: "#003366",
            marginLeft: theme.spacing(1),
        },
    },
    sidebar: {
        display: "none",
        position: "fixed",
        top: 0,
        left: 0,
        width: 300,
        minHeight: "100vh",
        height: "100%",
        background: "#FFF",
        zIndex: 9,
        borderRight: "1px solid #DDE3EE",
        padding: "32px 16px 24px",
        [theme.breakpoints.up("lg")]: {
            display: "block",
        },
    },
    content: {
        paddingLeft: 0,
        paddingTop: 0,
        [theme.breakpoints.up("lg")]: {
            paddingTop: 84,
            paddingLeft: 300,
        },
    },
    modal: {
        "& .MuiPaper-root": {
            width: 477,
            padding: "20px 32px 36px",
        },
    },
    dialogHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .MuiTypography-root": {
            fontWeight: 700,
            fontSize: 20,
            color: "#003366",
        },
        [theme.breakpoints.down("md")]: {
            "& .MuiTypography-root": {
                fontSize: 16,
            },
        },
    },
    closeButton: {
        minWidth: 32,
        width: 32,
        height: 32,
        borderRadius: 8,
        background: "#FFFFFF",
        border: "1px solid #DDE3EE",
    },
    dialogBody: {
        padding: theme.spacing(4, 0),
    },
    formGroupTitle: {
        fontWeight: 700,
        fontSize: 16,
        color: "#454D5F",
        marginBottom: 18,
    },
    selectBox: {
        marginBottom: 32,
        "& .MuiTypography-root": {
            fontSize: 14,
            color: "#6C7689",
            marginBottom: 2,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #A6AEBF",
            borderRadius: 8,
        },
    },
    dialogFooter: {
        display: "flex",
        justifyContent: "flex-end",
        "& .MuiButton-root": {
            width: 140,
            height: 56,
            borderRadius: 8,
            fontFamily: "Lato",
            fontSize: 16,
            fontWeight: 700,
            textTransform: "none",
            marginLeft: theme.spacing(2),
            border: "1px solid #003366",
            "&:first-child": {
                color: "#003366",
            },
            "&:last-child": {
                color: "#FFF",
                background: "#003366",
            },
        },
        [theme.breakpoints.down("md")]: {
            position: "absolute",
            bottom: 34,
            left: 24,
            right: 24,
            justifyContent: "space-between",
            "& .MuiButton-root": {
                marginLeft: 0,
                width: "48%",
            },
        },
    },
    caller: {
        display: 'flex',
        [theme.breakpoints.up("md")]: {
            display: 'none'
        }
    }
}));

const Admin = ({children}) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const [show, setShow] = React.useState(false);
    const doctorPhoneNumber = useSelector((state) => state.patient.data?.doctor?.phone_number);
    const [courier, setCourier] = useState('');
    const [support, setSupport] = useState('');

    const handleLogout = () => {
        history.push(Routes.Auth.Login);
        Storage.remove("user");
        Storage.remove("token");
    };

    const handleChangePassword = () => {
        history.push(Routes.Dashboard.ChangePassword);
    };

    useEffect(() => {
        API.settings.phone.show().then((res) => {
            setCourier(res.data.data[0].value.courier);
            setSupport(res.data.data[0].value.support);
        });
    }, []);

    return (
        <>
            <Box className={classes.root}>
                <Box className={classes.topbar}>
                    <Button className={classes.changePassword} onClick={handleChangePassword}>
                        <img src={KeyIcon} alt=":( Not Found"/>
                        <Typography>{t("layout.changePassword")}</Typography>
                    </Button>
                    <Button className={classes.logout} onClick={handleLogout}>
                        <img src={LogoutIcon} alt=":( Not Found"/>
                        <Typography>{t("layout.logout")}</Typography>
                    </Button>
                </Box>
                <Box>
                    <Box className={classes.sidebar}>
                        <Sidebar/>
                    </Box>
                    <Box className={classes.content}>{children}</Box>
                </Box>
                <MobileNavbar/>
            </Box>
            <Fab color="info" className={classes.caller} style={{
                position: 'fixed',
                top: 5,
                right: 20,
                zIndex: 999999,
                justifyContent: 'center',
                alignItems: 'center',
                color: '#fff'
            }} onClick={() => setShow(true)}>
                <img src={Phone} alt=":( Not Found" style={{height: 20}}/>
            </Fab>
            <Dialog className={classes.modal} open={show}>
                <Box className={classes.dialogHeader}>
                    <Typography>Zadzwoń do:</Typography>
                    <Button className={classes.closeButton} onClick={() => setShow(false)}>
                        <img src={CloseIcon} alt=":( Not Found"/>
                    </Button>
                </Box>
                <Box className={classes.dialogBody}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <a
                                href={`tel:${doctorPhoneNumber}`}
                                style={{
                                    textDecoration: 'none'
                                }}
                            >
                                <Button variant="outlined" disableElevation style={{width: '100%'}} color="success">Lekarza</Button>
                            </a>
                        </Grid>
                        <Grid item xs={12}>
                            <a
                                href={`tel:${courier}`}
                                style={{
                                    textDecoration: 'none'
                                }}
                            >
                                <Button variant="outlined" disableElevation style={{width: '100%'}} color="success">Kuriera</Button>
                            </a>
                        </Grid>
                        <Grid item xs={12}>
                            <a
                                href={`tel:${support}`}
                                style={{
                                    textDecoration: 'none'
                                }}
                            >
                                <Button variant="outlined" disableElevation style={{width: '100%'}} color="success">Wsparcia technicznego</Button>
                            </a>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </>
    );
};

export default Admin;
